@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Jomolhari&display=swap');
/* Renderer.css */
body {
  margin: 0;
  font-family: "Jomolhari", serif;
}

.renderer-section {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scene {
  width: 30vw; /* Adjust the width of the rectangle as needed */
  height: 80vh; /* Adjust the height of the rectangle as needed */
  border: 1px solid #ccc; /* Optional border for visibility */
  overflow: hidden; /* Prevent content from overflowing */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Renderer.css */
.scene-container {
  position: relative;
}

.toolbar {
  min-width: 80px;
  padding: 10px;
  background-color: #3498db;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.toolbar button {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 8px 16px;
  border-radius: 15px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.toolbar button:hover {
  background-color: white;
  color: black;
}
